import React from "react"
import { Link } from "gatsby"
import NewsHeader from "../../components/blog/blogHeader"
import NewsFooter from "../../components/blog/blogFooter"
import Footer from "../../components/footer"
import SEO from "../../components/seo"

import arrow from "../../../assets/images/servicesPages/arrow-right-min.png"
import seo1 from "../../../assets/images/blog/seo/seo1.jpg"
import seo2 from "../../../assets/images/blog/seo/seo2.jpg"
import seo3 from "../../../assets/images/blog/seo/seo3.jpg"
import seo4 from "../../../assets/images/blog/seo/seo4.jpg"
import seo5 from "../../../assets/images/blog/seo/seo5.jpg"
import seo6 from "../../../assets/images/blog/seo/seo6.jpg"
import seo7 from "../../../assets/images/blog/seo/seo7.jpg"
import seo8 from "../../../assets/images/blog/seo/seo8.jpg"
import seo9 from "../../../assets/images/blog/seo/seo9.jpg"


export default () => 
<div>
    <SEO title={"SEO Best Practices in 2021: The Definitive How-To Guide"} 
    description="Would you like to rank in Google’s first page but don’t know how? Discover the current SEO best practices on our blog."
    canonical={'https://icon-worldwide.com/blog/seo-best-practices'}
    image={'https://icon-worldwide.com/social_images/seo.jpg'} />

    <NewsHeader/>

    <div id="single-news">
            <h1><span>SEO Best Practices in 2021:</span> The Definitive How-To Guide</h1>
            <div id="title-underline"></div>
        <div id="single-news-texts">
            <img src={seo1} alt="SEO Best Practices in 2020" title="ICON, SEO Best Practices in 2020" style={{width:'100%', display:'block', margin:'0 0 30px'}}/>
            <p>In 2020, developing a cutting-edge website is no longer enough to be found by your buyer persona: in fact, the truth is that you absolutely need to rank in Search Engines’ first pages for your focus keywords.</p>
            <p><strong><Link to="/services/seo">Search Engine Optimization</Link> – the so-called SEO </strong>– which consists of a series of On-Page and Off-Page tactics that can make you position higher on SERPs, since (<a href="https://blog.hubspot.com/insiders/inbound-marketing-stats#:~:text=HubSpot's%20research%20shows%20that%2075,and%20be%20on%20our%20way." target="_blank" rel="noopener noreferrer">according to HubSpot</a>) <strong>75% of searchers never go past the first page of search results</strong>.</p>
            <h2>11 SEO Best Practices in 2020</h2>
            <img src={seo2} alt="11 SEO Best Practices in 2020" title="ICON, 11 SEO Best Practices in 2020" style={{width:'100%', display:'block', margin:'0 0 30px'}}/>
            <h3>1. Setting your SEO’s SMART Goals</h3>
            <p>The first best practice is a must for any kind of marketing strategy, including SEO’s:</p>
            <p>We’re talking about setting up your primary <strong><a href="https://www.mindtools.com/pages/article/smart-goals.htm" target="_blank" rel="noopener noreferrer">SMART goals</a></strong>. The <strong>‘SMART’ acronym stands for Specific, Measurable, Achievable, Relevant, and Time-based</strong>. To do so, you need to think about your website and answer the following starting questions:</p>
            <table class="colorful-table">
                <tr>
                    <th>Main Starting Questions</th>
                </tr>
                <tr>
                    <td>What’s your business’ main Unique-Selling-Proposition (USP) and buyer persona?</td>
                </tr>
                <tr>
                    <td>Till now, did you put into place any form of SEO strategy? If so, do you have any previous report, keyword research, or SEO audits?</td>
                </tr>
                <tr>
                    <td>Which actions and goals would be the most important ones on your site (e.g. subscriptions, lead generation, downloads)?</td>
                </tr>
                <tr>
                    <td>Do you have an ongoing content strategy? If so, which platforms and content are you adopting?</td>
                </tr>
                <tr>
                    <td>Are you already using the Google Search Console, Google Analytics, and SEO tools like SEMrush or Moz?</td>
                </tr>
                <tr>
                    <td>Have you ever planned a specific link building and guest posting strategy for your website and blog?</td>
                </tr>
                <tr>
                    <td>Are you actually using any specific focus keywords throughout the website?</td>
                </tr>
                <tr>
                    <td>Are you paying attention to your competitors or market leaders’ SEO strategies?</td>
                </tr>
            </table>
            <p>If you’ve correctly answered, you should already have in mind a <strong>starting picture of the main goals you’d like to achieve with your SEO strategy</strong>.</p>
            <h3>2. Planning a First – and Periodic – Site Audit</h3>
            <p>The first SEO step is to <strong>undertake a throughout Site Audit of your website</strong>, in order to identify and take note of any issues related to:</p>
            <ul>
                <li><strong>Crawlability and indexability</strong>;</li>
                <li><strong>Meta-tags, titles, headlines, URLs’ structure</strong>;</li>
                <li><strong><a href="https://moz.com/learn/seo/canonicalization#:~:text=A%20canonical%20tag%20(aka%20%22rel,content%20appearing%20on%20multiple%20URLs." target="_blank" rel="noopener noreferrer">Canonical tags</a> and redirects</strong>;</li>
                <li><strong>Duplicated content and cannibalization</strong>;</li>
                <li><strong>Page speed and mobile-friendliness</strong>;</li>
                <li><strong><a href="https://moz.com/beginners-guide-to-link-building" target="_blank" rel="noopener noreferrer">Link building</a>, both internal and external</strong>.</li>
            </ul>
            <p>To perform this analysis, you can use different <strong>SEO tools as <a href="https://www.semrush.com/" target="_blank" rel="noopener noreferrer">SEMrush</a>,<a href="https://moz.com/" target="_blank" rel="noopener noreferrer"> Moz</a>, <a href="https://ahrefs.com/" target="_blank" rel="noopener noreferrer">Ahrefs</a>, or <a href="https://en.ryte.com/?offer_id=2&aff_id=1939&url_id=43&aff_sub=1-g-Cj0KCQjwk8b7BRCaARIsAARRTL632mxXMmF9gVXo4Mw4HhG_IhBASShNmZV3bhmvno9DIGjiHgYy7PQaAuWIEALw_wcB&aff_click_id=1-g-Cj0KCQjwk8b7BRCaARIsAARRTL632mxXMmF9gVXo4Mw4HhG_IhBASShNmZV3bhmvno9DIGjiHgYy7PQaAuWIEALw_wcB" target="_blank" rel="noopener noreferrer">Ryte</a></strong>, but never forget that <strong>the help of a SEO expert can make you save a lot of efforts and time</strong>.</p>
            <img src={seo8} alt="Planning a First – and Periodic – Site Audit" title="ICON, Planning a First – and Periodic – Site Audit" style={{width:'100%', display:'block', margin:'0 0 30px'}}/>
            <p>SEO audits <strong>should be reviewed periodically</strong>, because a SEO strategy is dynamic and needs to be updated month by month to identify any new threats and opportunities.</p>
            <h3>3. Figuring out your Clients’ Search Intent </h3>
            <p>After the Site Audit, it’s the turn of the implementations, which include the <strong>on-page SEO measures – based on the site audit’s results</strong> – that should be undertaken by the SEO expert and the <Link to="/services/website-development">website developer</Link>.</p>
            <p>This is surely the most crucial part of any SEO strategy, and that’s why we’re going to split it up across the rest of the article.</p>
            <p>Yet, prior to start, the first thing you need to pay attention to is the so-called <strong>‘<a href="https://ahrefs.com/blog/search-intent/" target="_blank" rel="noopener noreferrer">Search Intent</a>’ (also called ‘User Intent’), namely your potential clients’ purpose behind their search query</strong>, to which your content should be aligned with (e.g. buying, learning, subscribing). This is important because <strong>satisfying the search intent is Google's pivotal priority</strong> and all your pages have to pass Google's test on search intent.</p>
            <a class="no-decoration" href="/services/website-development/"><div class="silver-btn">DISCOVER MORE ABOUT ICON’S SEO EXPERTISE</div></a>
            <h3>4. Improving Page Title, Meta-description, URL, and Headings</h3>
            <p>Now, we can dive into <strong>some more practical steps</strong>: your SEO implementations should be mainly based on structuring the following elements.</p>
            <h4>Page Title </h4>
            <p><strong>The Title Tag</strong> – or Page Title – <strong>is the clickable headline that’s shown in search results</strong>.</p>
            <p><strong>It is the first piece of information seen by the users, so it can really drive their decision to click on your link or not</strong>, as well as being one of the elements that Google’s algorithms take into consideration when it comes to position your web pages.</p>
            <img src={seo9} alt="Page Title " title="ICON, Page Title " style={{width:'100%', display:'block', margin:'0 0 30px'}}/>
            <p>So, in order to create the perfect title tags, you should:</p>
            <ul>
                <li><strong>Keep them descriptive and concise, under 60 characters, to be fully displayed</strong>;</li>
                <li><strong>Include your focus keywords</strong>;</li>
                <li><strong>Always write a title that matches the search intent and the page topic</strong>;</li>
                <li><strong>Don’t create duplicates: the title should be unique on each page</strong>;</li>
                <li><strong>Avoid <a href="https://blog.alexa.com/keyword-stuffing/" target="_blank" rel="noopener noreferrer">keyword stuffing</a></strong>.</li>
            </ul>
            <h4>Meta-description</h4>
            <p>On the other side, <strong>the meta description is a brief summary of what the page is about, displayed right below the page title</strong>, which can highly influence your <a href="https://support.google.com/google-ads/answer/2615875?hl=en" target="_blank" rel="noopener noreferrer">click-through rates</a>.</p>
            <img src={seo3} alt="Meta-description" title="ICON, Meta-description " style={{width:'100%', display:'block', margin:'0 0 30px'}}/>
            <p>Before writing it, just remember to:</p>
            <ul>
                <li><strong>Keep it under 160 characters</strong>;</li>
                <li><strong>Write unique descriptions for each page</strong>;</li>
                <li><strong>Be action-oriented and include a call-to-action</strong>;</li>
                <li><strong>Include your focus keywords and match the search intent</strong>.</li>
            </ul>
            <h4>URL</h4>
            <p>Moreover, <strong>also a well-structured URL provides both users and search engines with an idea of what the page is about</strong>, so it’s an important SEO aspect. To create it, remember that it should:</p>
            <ul>
                <li><strong>Be short and descriptive</strong>;</li>
                <li><strong>Include the focus keyword</strong>;</li>
                <li><strong>Never involve any unnecessary <a href="https://cseo.com/blog/seo-stop-words/" target="_blank" rel="noopener noreferrer">stop words</a></strong>;</li>
                <li><strong>Be written according to the <a href="https://ahrefs.com/blog/what-is-https/" target="_blank" rel="noopener noreferrer">HTTPS format</a>, as it makes the page be more secure and is been a Google ranking factor since 2014</strong>.</li>
            </ul>
            <h4>Headings</h4>
            <p>And finally, never forget to optimize your headings, which would provide your pages with the <strong>correct SEO copywriting structure</strong>.</p>
            <img src={seo4} alt="Headings" title="ICON, Headings" style={{width:'100%', display:'block', margin:'0 0 30px'}}/>
            <p><strong>Pay attention above all to the H1, which should be unique for each page and include the target keyword, and always use the subheadings properly (H1, H2, H3, H4)</strong> to help both search engines and users understand – and read – your content.</p>
            <h3>5. Optimizing your Website’s Images</h3>
            <p>Another SEO best practice is <strong>image optimization, both in terms of ALT texts, titles, and format</strong>, since they play a crucial role in improving your page speed and user experience. </p>
            <p>To optimize them, you should:</p>
            <ul>
                <li><strong>Choose the most suitable file format to improve your website’s speed</strong>: while the JPEG format looks great for photographs, the PNG one is more suited to images that contain text and drawings;</li>
                <li><strong>Compress them before uploading on your website</strong>, to make the navigation faster, by adopting tools like <a href="https://tinypng.com/" target="_blank" rel="noopener noreferrer">TinyPNG</a>, <a href="https://imageoptim.com/mac" target="_blank" rel="noopener noreferrer">ImageOptim</a>, or <a href="https://shortpixel.com/pricing-one-time" target="_blank" rel="noopener noreferrer">ShortPixel</a>;</li>
                <li><strong>Provide them with an ALT text and title that include the focus keywords</strong>, to improve accessibility and voice search, by helping browsers understand what your images are about.</li>
            </ul>
            <h3>6. Paying Attention to your Page Speed</h3>
            <p>As above-mentioned, <strong>Page Speed is another meaningful ranking factor</strong> and should be part of your implementation measures.</p>
            <img src={seo5} alt="Paying Attention to your Page Speed" title="ICON, Paying Attention to your Page Speed" style={{width:'100%', display:'block', margin:'0 0 30px'}}/>
            <p>Luckily, there are plenty of <strong>tools to check your page speed, including Google <a href="https://developers.google.com/speed/pagespeed/insights/" target="_blank" rel="noopener noreferrer">PageSpeed Insights</a>, SEMrush, and <a href="https://gtmetrix.com/" target="_blank" rel="noopener noreferrer">GTMetrix</a></strong>, where you’ll grab also some meaningful recommendations on the steps you should take to improve your load time, such as:</p>
            <ul>
                <li><strong>Enabling browser caching</strong>;</li>
                <li><strong>Removing any unnecessary plugins</strong>;</li>
                <li><strong>Reducing the number of redirects</strong>;</li>
                <li><strong>Minifying CSS and JavaScript files</strong>;</li>
                <li><strong>Using a CDN for international websites</strong>;</li>
                <li><strong>Compressing images, as we’ve already said</strong>; </li>
                <li><strong>Adopting <a href="https://web.dev/lazy-loading/" target="_blank" rel="noopener noreferrer">lazy-loading</a></strong>.</li>
            </ul>
            <h3>7. Looking for the Best Focus Keywords</h3>
            <p>Besides, <strong>mastering a keyword research is one of the most important steps for any SEO expert</strong>, and it can be done by using SEO tools like SEMrush or Moz.</p>
            <img src={seo6} alt="Looking for the Best Focus Keywords" title="ICON, Looking for the Best Focus Keywords" style={{width:'100%', display:'block', margin:'0 0 30px'}}/>
            <p>In this regard, <strong>always remember that the best keywords – indicatively – are the ones with the lower difficulty and the higher volume</strong>, due to it is simpler to compete for them</p>
            <p>Furthermore, you should know that long-form content ranks better on Google, as a <a href="https://www.semrush.com/blog/anatomy-of-top-performing-articles/" target="_blank" rel="noopener noreferrer">recent SEMrush research</a> shows: so, <strong>when creating long-form content for your blog, try to target long-tail keywords</strong> even if they have a lower search volume.</p>
            <h3>8. Planning a Strong Link Building Strategy</h3>
            <p>After that, <strong>one of the SEO best practices you cannot ignore is the Link Building strategy</strong>.</p>
            <p>In fact, Google algorithms still consider backlinks a remarkable ranking factor: more backlinks you have, the higher you can rank in SERPs.</p>
            <p>One of the best ways to discover new backlinks opportunities is by <strong>replicating your competitors' strategies</strong>, studying them with tools like <strong>SEMrush’s <a href="https://www.semrush.com/kb/773-backlink-gap" target="_blank" rel="noopener noreferrer">Backlink Gap Tool</a> and Backlink Audit</strong>.</p>
            <h3>9. Optimizing for Voice Search and Featured Snippets</h3>
            <img src={seo7} alt="Optimizing for Voice Search and Featured Snippets" title="ICON, Optimizing for Voice Search and Featured Snippets" style={{width:'100%', display:'block', margin:'0 0 30px'}}/>
            <p>Moreover, <strong><a href="https://searchengineland.com/welcome-bert-google-artificial-intelligence-for-understanding-search-queries-323976" target="_blank" rel="noopener noreferrer">BERT</a> – the new Google’s algorithm – understands human language and questions</strong>: this means that SEO experts should focus on creating the most useful and natural content, as – in 2020 – <strong>20% of mobile queries are currently done with voice search</strong> (<a href="https://review42.com/voice-search-stats/" target="_blank" rel="noopener noreferrer">Review42</a>).</p>
            <p>So, in order to optimize your content for voice search, you should: </p>
            <ul>
                <li><strong>Insert a question and an answer within your pages</strong>;</li>
                <li><strong>Try to rank in the <a href="https://support.google.com/webmasters/answer/6229325?hl=en" target="_blank" rel="noopener noreferrer">Featured Snippet</a>, the so-called Position Zero</strong>, as it seems that 4 out of 10 voice search results come directly from them (<a href="https://www.semrush.com/blog/voice-search-study/" target="_blank" rel="noopener noreferrer">SEMrush</a>).</li>
             </ul>
             <h3>10. Doing Off-Page SEO</h3>
             <p>Last but not least, after completing the On-Page best practices, you can move forward with the Off-Page steps, which refer to <strong>all those channels and platforms that can boost your online presence and traffic, both organically and paid</strong>, as:</p>
             <ul>
                 <li><strong>Social media platforms and ads</strong>;</li>
                 <li><strong>Blog, guest posts, and backlinks</strong>; </li>
                 <li><strong><a href="" target="_blank" rel="noopener noreferrer">Google Ads</a></strong>;</li>
                 <li><strong>PR and Influencer Marketing</strong>.</li>
             </ul>
             <h2>Conclusions</h2>
             <p>As we’ve seen, <strong>SEO is still one of the most important marketing aspects in 2020</strong>, and its best practices are always evolvin</p>
             <p>Therefore, if you aren’t a SEO expert and haven’t a dedicated team within your company, you should start paying attention to your business’ goals, buyer persona’s needs, and creative resources.</p>
             <p>After that, if you really want to make a difference, <strong>you should look for the help of a valuable SEO expert</strong>.</p>
        </div>


        <img src={arrow} id="single-news-horizontal-arrow" alt="ICON Worldwide arrow" title="ICON Worldwide arrow"/>
        <p><strong>Do you need some help to improve your SEO website strategy? If so, just contact ICON’s team to get a free quote!</strong></p>
        <h4><Link to="/contact-us" className="cta">GET YOUR CONSULTATION</Link></h4>

            <NewsFooter previous="zero-party-data-healthcare-industry" next="magento-vs-woocommerce"/>
        
    </div>

    <Footer noScrollbar="true"/>
</div>